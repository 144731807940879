<template>
  <div class="relative">
    <button class="btn btn-light atualizar" :disabled="loading" @click="refresh">
      <font-awesome-icon :icon="['fas', 'sync-alt']"  :class="loading && 'fa-spin'" style="margin:0!important"/>
    </button>
  </div>
  <div class="row justify-content-center" id="notifications">
    
    <div class="col-lg-6">
      <div class="row">
        <div class="col-lg-12" v-if="items.length == 0 && !loading">
          <div class="text-center">
            <font-awesome-icon :icon="['far', 'bell-slash']" size="6x" class="mb-3"/>
            <h2>Você não tem notificações.</h2>
          </div>
        </div>
          <div class="col-lg-12" v-for="i in items" :key="i.codigoNotificacaoEnvio">
            <router-link :to="i.link || '#'" class="body">
              <div class="img-container">
                <img :src="i.imagemUrlFormatado"/>
              </div>
              <div class="text-center message-container">
                <div class="text-right">
                  <small>
                    {{i.dataCadastro}}
                  </small>
                </div>
                <div>
                  {{i.mensagem}}
                </div>
                <div  class="text-right" >
                  <small v-if="!i.lido" class="badge bg-success">
                    NOVO!
                  </small>
                </div>
              </div>  
            </router-link>
          </div>
        <div class="loading mb-3 text-center" >
            <font-awesome-icon :icon="['fas', 'sync-alt']" spin v-if="loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .text-right{
    text-align: right
  }
  #notifications .body{
    border: 1px solid var(--light);
    padding-right: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: 4px;
  }
  .badge{
    min-height: 20px
  }
  .body {
    background: var(--white);
    height: 150px;
    display: block;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: var(--dark);
  }

  .body .img-container{
    height: 100%;
    background-color: var(--darker);
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow:hidden;
    position:relative;
    width: 25%;
    width: 35%;
  }
  .body .img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    
  }
  .body .message-container{
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.75rem;
  }
  .body .message-container div{
    width: 100%
  }
  .text-center{
    text-align:center
  }
  h2{
    margin-top:1rem;
    margin-bottom:1rem;
    text-align: center;
  }
  .atualizar{
    position: absolute;
    top:-86px;
    right:0;
  }
  .relative{
    position:relative;
    
  }
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'

import { faBellSlash } from '@fortawesome/free-regular-svg-icons'
import api from '@/services/api';
import {relativeTime} from '@/services/util';
import {companyPath} from '@/services/auth';


library.add(faBellSlash);

export default {
  
  name: 'Notifications',
  data(){
    return {
      items: [],
      total: 0,
      loading: true,
      pagination: {
        NumeroPagina: 1,
        TamanhoPagina: 4,
        DeslocamentoPagina: 1
      },
      hasMore: false,
    }
  },
  methods: {
    async get() {
      this.loading = true;
      
      let isFirstPage = this.pagination.NumeroPagina === 1;

      if(isFirstPage) {
        this.hasMore = true;
      }
      let response = await api.get('/v1/notificacoes', {
        params: {
          ...this.pagination,
        },
      });

      let { data, totalRegistros} = response.data;

      for(let i in data) {
        data[i].dataCadastro = relativeTime(data[i].dataCadastro);
        if(data[i].link)
          data[i].link = data[i].link.replace('/ofertas','');
          // data[i].imagemUrlFormatado = `${location.protocol}//${companyPath}.repasys.com.br${data[i].imagemUrl}`
      }

      this.items = isFirstPage ? data : [ ...this.items,...data];
      this.total = isFirstPage ? totalRegistros : this.total;

      this.hasMore = data.length === this.pagination.TamanhoPagina;
      this.loading = false;
      this.read();
    },
    getMore() {
      let that = this;
      let footerHeight = document.querySelector('.footer-layout').scrollHeight + 
                         document.querySelector('.copyright').scrollHeight;
      window.onscroll = async () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + footerHeight >= document.documentElement.offsetHeight;
        
        if (bottomOfWindow && that.hasMore && !that.loading) {
          that.loading = true;
          that.pagination.NumeroPagina++;
          await that.get();
        }
      }
    },
    async read() {
      let reads = this.items.filter(f=>!f.lido).map(f=>f.codigoNotificacaoEnvio);
      console.log(reads.length);
      if(reads.length) {
        await api.post(`/v1/notificacoes/ler`, reads)
      }
    },
    async refresh() {
      this.pagination.NumeroPagina = 1;
      await this.get();
    }
  },
  async mounted(){
    await this.get();
    await this.getMore();
  },
  
}
</script>